.pack {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}
.brick {
  display: flex;
  flex-flow: column;
  height: 100%;

  .brick__title {
    color: inherit;
    font-family: $font-family-bold;
    margin: 0;

    &:focus {
      outline: none;
    }
  }
}

.brick--color {
  background-blend-mode: luminosity;
}

.brick--color-1 {
  background-color: rgba($base-color, .7);
} 

.brick--color-2 {
  background-color: rgba($brand-primary, .7);
}

.brick--color-3 {
  background-color: rgba($brand-select, .7);
}
