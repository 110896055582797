.col-md-15,
.col-md-25 {
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

@media (min-width: $screen-md-min) {

  .col-md-15 {
    float: left;
    width: 20%;
  }

  .col-md-25 {
    float: left;
    width: 40%;
  }
}

@media (min-width: $screen-sm-min) {
    
  .col-sm-25 {
    width: 36%;
  }

  .col-sm-15 {
    width: 28%;
  }
}