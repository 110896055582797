.label-corner {
  display: flex;
  height: 28px;
  position: absolute;

  .svg {
    background-color: $brand-primary;
    padding: 5px;
    width: 30px;

    path {
      fill: $color-text;
    }
  }

  .label-corner__text {
    align-items: center;
    background: $base-color;
    color: $color-text;
    display: inherit;
    padding: 0 10px;
  }
}
