.main-pack {

  @media screen and (min-width: $screen-md-min) {
    height: 600px;
    overflow: scroll;
  }
}

.main-brick {
   min-height: 300px;
}

.main__image--color {
  background-color: rgba($base-color, .6);
  padding: 20px 15px;

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
    padding: 20px 30% 50px 15px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 40px 30% 50px 30px;
  }
}

.main__image--title {
  font-size: 8vw;
  line-height: 120%;
  width: auto;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 60px;
  }
}

.main__quote{
  font-size: $font-size-msmall;
  margin: 0;
}

.main__subtitle {
  font-family: $font-family-bold;
  font-size: $font-size-xsmall;
  margin: 10px 0;

  @media screen and (min-width: $screen-mg-min) {
    font-size: $font-size-llarge;
  }
}

.box-info {
  background: $darker-strong;

  .box-info__item {
    font-size: $font-size-ssmall;
    margin: 5px 0;
  }

  .box-info__item--quote {
    color: $brand-info;
    letter-spacing: 0.4px;
  }

  .box-info__item--subtitle {
    color: $brand-select;
    letter-spacing: 0.4px;
  }

  .box-info__item--margin {
    margin-top: 15px;
  }
}

.box-info__main {
  color: $color-text;
  display: block;
  font-size: $font-size-xsmall;
  line-height: 17px;
}

.box-info__main--size {
  font-size: $font-size-xsmall;
  height: 33px;
  overflow: hidden;
}

.box-info--weight {
  font-family: $font-family-bold;
}

.container-article {
  height: auto;
}

.article {
  border: 1px solid #f2f2f2;
  height: 260px;
  padding: 0;
  transition: all 0.3s linear;

  @media screen and (max-width: $screen-ss-max) {
    width: 100%;
  }

  @media screen and (min-width: $screen-md-min) {

    &:hover {
      transform: scale(1.02, 1.02);
      z-index: 1;
      box-shadow: 0 0 13px 8px $color-text;
    }
  }

  .article__image {
    height: 65.4%;
  }

  .article__box-info {
    display: flex;
    flex-flow: column;
    height: 34.6%;
    justify-content: center;
    padding: 0 15px;
    width: calc(100% - 50px);
  }
}
