/*
|--------------------------------------------------------------------------
| Common Styles
|--------------------------------------------------------------------------
*/

* {
  color: $color-text;
  font-family: $font-family-base;
  text-transform: uppercase;
}

*::selection {
  background: $darker;
  color: $color-text;
}

a:hover, a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.text--hover {
  color: $brand-primary;
  font-size: 12px;

  &:hover, &:focus {
    background: $darker-strong;
    color: $brand-select;
    outline: none;
  }
}

.header--space {
  background-color: $brand-darker;
  padding: 45px 0 0;
}

.container-box {
  margin: auto;
  max-width: 1500px;
}

.item--filler {
  padding: 0;
}

.label--filler {
  margin: 0;
}

:root {
  background: $brand-darker;
}

::-webkit-scrollbar { 
    display: none; 
}

body.active {
  overflow: hidden;
}