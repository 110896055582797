.videowrapper {
  align-items: center;
  display: flex;
  height: 180px;
  justify-content: center;
}

.videowrapper-main {
  height: 720px;
}

.videowrapper-main__box {
  height: 540px;
}

.videowrapper-label {
  display: flex;
  flex-flow: column;
  height: 180px;
  justify-content: center;
  padding: 0 10px;

  .videowrapper-label__subtitle {
    color: $brand-select;
    margin: 5px 0;
  }
}

.videowrapper-sidebar {
  height: auto;
  cursor: all-scroll;
  overflow: scroll;
}

.videowrapper-sidebar__label {
  align-items: center;
  display: flex;
  height: 50px;
  padding: 0 10px;
  position: relative;
}

.videowrapper__icon-play {
  border-radius: 50%;
  cursor: pointer;
  height: 40%;

  &:hover, &:focus {
    background: rgba($dark, 0.4);
  }
}
