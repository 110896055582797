.single-container {
  background: $color-text;
}

.single {
  color: $dark;
  font-family: $font-family-thin;
  font-size: $font-size-xsmall;
  height: auto;
  padding: 20px;
  text-transform: none;

  @media screen and (min-width: $screen-sm-min) {
    font-size: $font-size;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 50px 250px;
  }

  #share {
    
    span{
      color: $color-text;
      font-size: $font-size;
      margin: 0;
      text-transform: none;
    }

    i {
      margin: 10px 0;
    }

    .jssocials-share-link{
      padding-top:0;
      padding-bottom: 0;
    }
  }

  iframe, video {
    border: 0;
    margin: 10px 0;
    width: 100%;
  }

  img {
    display: block;
    height: auto;
    margin: auto;
    max-width: 100%;
    width: auto;
  }

  iframe, video {
    height: 400px;
    padding-top: 15px;
  }

  p, span, b, strong, li, em, i:not(.fa), table, tr, th, td {
    color: inherit;
    font-family: inherit !important;
    font-size: inherit;
    margin: 20px 0;
    text-transform: none;
  }

  strong {
    font-family: $font-family-bold !important;
  }

  h1,h2,h3,h4,h5,h6 {
    font-size: $font-size-mlarge;
    margin: 20px 0;
  }

  ul {
    color: inherit;
    list-style: circle; 
    padding: 15px;
  }

  blockquote {
    border: none;
    color: $brand-primary;
    font-size: $font-size-xsmall;
    margin: 10px 0;
    padding: 20px;
    position: relative;
    text-transform: none;

    &:before, &:after {
      color: rgba($brand-primary, .4);
      font-size: 150px;
      line-height: 0;
      position: absolute;
      vertical-align: bottom;
    }

    &:after {
      content: close-quote;
      right: 10px;
      bottom: 10px;
    }

    &:before {
      content: open-quote;
      left: 0;
      top: 64px;
    }

    @media screen and (min-width: $screen-sm-min) {
      float: right;
      max-width: 35%;
      width: auto;
    }

    span {
      color: rgba($brand-primary, .4);
      margin-top: 5px;
    }
  }

  .single__box-img {
    margin: 20px -20px;

    @media screen and (min-width: $screen-md-min) {
      margin: 20px -250px;
    }
  }
}