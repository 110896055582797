.footer {
  background: $darker-strong;

  .footer__logo {
    margin: 20px 0 10px -9px;
    width: 140px;
  }

  .footer__tittle {
    color: $brand-info;
    font-size: $font-size-h-6;
    text-transform: uppercase;
  }

  .footer__info {
    font-family: $font-family-thin;
    font-size: 12px;
    text-transform: none;
  }

  .footer__info--size {
    padding-right: 100px;

    @media screen and (max-width: $screen-md-max) {
      padding-right: 10px;
    }
  }

  .footer__info--inline {
    display: inline-block;
    margin: 41px 24px 0 0;
    vertical-align: bottom;

    @media screen and (max-width: 1164px) {
      margin: 0 24px 10px 0;
    }

    @media screen and (max-width: $screen-xs-max) {
      display: block;
    }
  }

  .footer__button {
    border: 2px solid $brand-primary;
    padding: 3px 15px;
  }

  .footer__button--email {
    background: $darker-strong;
    border-radius: 4px;
    text-transform: uppercase;

    &::-webkit-input-placeholder {
      color: $brand-primary;
    }

    &:placeholder-shown {
      background: rgba($brand-primary, .4);

      &:hover {
        background: rgba($brand-select, .4);
      }
    }
  }

  .footer__button--input {
    border: solid 1px;
    margin: 0 20px 0 0;
    width: 220px;

    @media screen and (max-width: $screen-mg-max) {
      margin: 0 20px 15px 0;
    }

    @media screen and (max-width: $screen-xs-max) {
      display: block;
      width: 70%;
    }


  }

  .footer__icons {
    padding-bottom: 8px;

    .footer__icons--size {
      margin: 0 13px 0 0;
      width: 23px;

      &:hover {

        path {
          fill: $brand-select;
        }
      }
    }

    .footer__icons--size-2 {
      width: 33.1px;
    }

    .footer__icons--size-1 {
      width: 27.4px;
    }
  }

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max)  {

    .footer__column {
      width: 50%;
    }

    .footer__column--contact {
      right: 50%;
    }

    .footer__column--follow {
      left: 50%;
    }
  }

  .footer__basement {
    border-top: 2px solid $brand-select;
    color: $brand-primary;
    margin-top: 30px;
    padding: 5px;

    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      justify-content: center;
    }

    .basement__text {
      color: $brand-primary;
      font-size: $font-size-msmall;

      @media screen and (min-width: $screen-xs-min) {
        padding-left: 30px;
      }
    }
  }
}
