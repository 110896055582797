.home-brick--banner {
  background-size: 100% 100%;
}

.home__brick--bg-1a {
  background-image: url(../images/home/giphy_5.gif);
}

.home__brick--bg-1b {
  background-image: url(../images/home/formula-1.jpg);
}

.home__brick--bg-2a {
  background-image: url(../images/home/publicidad-3.jpg);
}

.home__brick--bg-2b {
  background-image: url(../images/home/nascar.gif);
}

.home__brick--bg-2c {
  background-image: url(../images/home/supercopa.jpg);
}

.home__brick--bg-3a {
  background-image: url(../images/home/escuderia-telmex.jpg);
}

.home__brick--bg-3b {
  background-image: url(../images/home/publicidad-1.jpg);
}

.home__brick--bg-4a {
  background-image: url(../images/home/giphy_14.gif);
}

.home__brick--bg-4b {
  background-image: url(../images/home/le-mans.jpg);
}

.home__brick--bg-4c {
  background-image: url(../images/home/lo-mas.jpg);

  @media screen and (min-width: $screen-sm-min) {
    background-position-y: 0;
  }
}

.home__brick--bg-5a {
  background-image: url(../images/home/rallys.jpg);
}

.home__brick--bg-5b {
  background-image: url(../images/home/formula-e.jpg);
}

.home__brick--bg-5c {
  background-image: url(../images/home/publicidad-2.gif);
}

@media screen and (max-width: $screen-ss-max) {

  .home_brick--bg-mobile {
    background-size: 130% 100%;
  }
}

.image-bg {
  height: 150px;
}

.carousel-item__bg {
  height: 320px;

  @media screen and (max-width: $screen-ss-max) {
    height: 280px;
    left: 0;
    width: 100%;
  }
}

.advertising__bg {
  background-image: url(../images/advertising12.jpg);
  height: 350px;
}

@media screen and (min-width: $screen-md-min) {
  .notfound__image--primary {
    width: 100%;
  }

  .notfound__image--default {
    display: none;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .notfound__image--primary, .notfound__image--success {
    display: none;
  }

  .notfound__image--default {
    width: 100%;
  }
}
