/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/

$base-color:           #00b2db;
$brand-primary:        #009ceb;
$brand-select:         #21bfb9;
$brand-info:           #85c396;
$brand-success:        #006d8e;
$brand-darker:         #0a000f;
$dark:                 #4d4d4d;
$darker:               #292f23;
$darker-strong:        #1a1a1a;
$color-text:           #f2f2f2;

/*
|--------------------------------------------------------------------------
| Fonts Size
|--------------------------------------------------------------------------
*/

$font-size:           16px !default;
$font-size-xlarge:    ceil(($font-size * 2.04)) !default; // ~30px
$font-size-mlarge:    ceil(($font-size * 1.45)) !default; // ~24px
$font-size-llarge:    ceil(($font-size * 1.25)) !default; // ~20px
$font-size-slarge:    ceil(($font-size * 1.12)) !default; // ~18px
$font-size-xsmall:    ceil(($font-size * 0.85)) !default; // ~14px
$font-size-msmall:    ceil(($font-size * 0.65)) !default; // ~11px
$font-size-ssmall:    ceil(($font-size * 0.55)) !default; // ~9px

$font-size-h-1:       floor(($font-size * 2.50)) !default; // ~40px
$font-size-h-2:       floor(($font-size * 2.15)) !default; // ~34px
$font-size-h-3:       ceil(($font-size * 1.75)) !default;  // ~28px
$font-size-h-4:       ceil(($font-size * 1.25)) !default;  // ~20px
$font-size-h-5:       ceil(($font-size * 0.93)) !default;  // ~15px
$font-size-h-6:       ceil(($font-size * 0.85)) !default;  // ~14px

/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
*/

$font-family-bold: 'Raleway-bold';

@font-face {
  font-family: 'Raleway-bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/raleway/Raleway-Black.ttf') format('truetype');
}


$font-family-base: 'Raleway-regular';

@font-face {
  font-family: 'Raleway-regular';
  font-style: normal;
  font-weight: bolder;
  src: url('../fonts/raleway/Raleway-SemiBold.ttf') format('truetype');
}

$font-family-thin: 'Raleway-thin';

@font-face {
  font-family: 'Raleway-thin';
  font-style: normal;
  font-weight: thin;
  src: url('../fonts/raleway/Raleway-Regular.ttf') format('truetype');
}

/*
|--------------------------------------------------------------------------
| Media Queries Breakpoints
|--------------------------------------------------------------------------
*/

$screen-ss-min:    320px;
$screen-xs-min:    480px;
$screen-sm-min:    768px;
$screen-md-min:    992px;
$screen-mg-min:    1024px;
$screen-lg-min:    1200px;
$screen-gd-min:    1824px;

$screen-ss-max:    ($screen-xs-min - 1);
$screen-xs-max:    ($screen-sm-min - 1);
$screen-sm-max:    ($screen-md-min - 1);
$screen-md-max:    ($screen-mg-min - 1);
$screen-mg-max:    ($screen-lg-min - 1);
$screen-lg-max:    ($screen-gd-min - 1);
