/*
|--------------------------------------------------------------------------
| Errors pages styles
|--------------------------------------------------------------------------
*/

.notfound {
  background-color: $brand-primary;
  padding-bottom: 50px;
  @media screen and (max-width: $screen-sm-max) {
    padding: 150px 0 100px 0;

    .notfound__content {
      bottom: 0;
      text-align: center;
      width: 100%;
    }

    .btn-primary-back {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    .notfound__content {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-30%, -50%);
      width: 30%;
    }

    .btn-primary-back {
      margin-top: 25px;
      float: right;
    }
  }
}
