@media screen and (min-width: $screen-md-min) {

  @keyframes slide-arrow {

    0% {
      transform: none;
    }

    25% {
      transform: translateX(10px);
    }

    50% {
      transform: translateX(-10px);
    }

    100% {
      transform: none;
    }
  }
}

.header__button {
  display: none;
  float: left;
  height: 29px;
  padding: 0 6px;
  transition: margin .2s ease;
  width: calc(100% - 170px);

  @media screen and (max-width: $screen-sm-max) {
    display: inline-block;
  }

  .icon-bar {
    display: block;
    height: 3px;
    transition: all 0.2s;
    width: 30px;
  }

  .top-bar {
    background: $brand-primary;
    transform: rotate(0);
  }

  .middle-bar {
    background-color: $brand-select;
    opacity: 1;
    transition: opacity 0.1s;
  }

  .bottom-bar {
    background: $brand-info;
    transform: rotate(0);
  }

  &.active {

    .top-bar {
      transform: rotate(45deg);
      transform-origin: 20% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }
}

.header-block__button {
  align-items: center;
  background: inherit;
  color: $brand-primary;
  display: flex;
  flex-flow: column;
  font-size: $font-size-llarge;
  height: 45px;
  justify-content: center;
  padding: 9px;
  text-align: center;

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-mg-max) {
    font-size: $font-size-msmall;                
  }

  @media screen and (min-width: $screen-lg-min) {
    font-size: 12px;
  }

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    padding: 50px 9px 0;
  }

  @media screen and (max-width: $screen-sm-max) {
    border: 1px solid rgba($dark, .2);
    height: 100%;
  }

  .header-button__icon {
    margin: 0 0 20px;

    @media screen and (min-width: $screen-sm-min) {
      width: 40%;
    }

    @media screen and (min-width: $screen-md-min) {
      display: none;
    }
  }

  .header-button__icon--news {
    width: 60px;
  }
}

.button-go {
  display: flex;
  justify-content: center;

  &:hover {

    .button-go__dart {
      animation: slide-arrow 85ms ease-in-out;
    }
  }
}

.button-go--home {
  border-radius: 30px;
  height: 35px;
  margin: 5px 0;
  padding: 0;
  position: relative;
  width: 35px;

  &:hover {
    animation: slide-arrow 85ms ease-in-out;
  }
}

.button-go--news {
  background: $base-color;
  height: inherit;
  position: absolute;
  right: 0;
  width: 50px;

  .button-go__dart--news {
    width: 30px;
  }
}

.button-go--sidebar {
  background: $brand-primary;
  bottom: 0;
  height: 35px;
  position: absolute;
  right: 0;
  width: 45px;

  .button-go__dart--sidebar {
    width: 25px;
  }
}

.button-go--video-sidebar {
  background: $brand-primary;
  height: inherit;
  position: absolute;
  right: 0;
  width: 50px;

  .button-go__dart--video-sidebar {
    width: 35px;
  }
}

.deployment {
  background: $darker;
  color: $color-text;
  cursor: pointer;
  font-family: $font-family-bold;
  font-size: $font-size-mlarge;
  padding: 10px 0;
  text-align: center;
  transition: all 0.3s ease;

  @media screen and (min-width: $screen-md-min) {
    padding: 20px 0;

    &:hover, &:focus {
      background: $color-text;
      color: $darker;
    }
  }
}

.btn-primary {
  background-color: $brand-success;
  border: none;
  border-radius: 0;
  color: $color-text;
  padding: 6px 20px;
  text-transform: initial;
}
