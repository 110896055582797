.box-title {
  background-color: $base-color;

  .box-title__title {
  color: $color-text;
  display: inline-block;
  font-family: $font-family-base;
  font-size: $font-size-mlarge;
  margin: 0;
  padding: 13px 0;
  }
}

.videowrapper-sidebar__title {
  height: 30px;
  overflow: hidden;
	width: calc(100% - 50px);
}