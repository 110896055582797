.container-box--header {
  background: $darker-strong;
  height: 45px;
  left: 0;
  position: fixed;
  right: 0;
  transition: height .4s ease; 
  z-index: 2;

  @media screen and (min-width: $screen-sm-min) {
    background: $darker;
  }
}

.header__group {
  height: 100%;
  position: relative;

  .header__block {
    display: flex;
    justify-content: space-between;
    transition: all .25s ease;
    transition: opacity .4s ease;
    width: calc(100% - 170px);

    @media screen and (min-width: $screen-lg-min) {
      padding-left: 40px;
    }

    @media screen and (max-width: $screen-sm-max) {
      right: 100%;
      bottom: 100vh;
      opacity: 0;
      position: relative;
    }

    &.active {
      bottom: 0;
      flex-flow: wrap;
      height: calc(100vh - 45px);
      opacity: 1;
      overflow: scroll; 
      right: 0;
      width: 100%;
    }

    .block__box {
      background: $darker;
      height: 150px;
      width: 50%;

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        background: $darker-strong;
        height: 50%;
        width: 20%;
      }

      @media screen and (min-width: $screen-md-min) {
        height: 45px;
        width: auto;
      }

      &:focus, &:hover {

        .header-block__button {
          background: $darker-strong;
          color: $brand-select;

          .svg {

            path {
              fill: $brand-select;
            }
          }
        }
      }
    }
  }

  .header__box-logo {
    position: absolute;
    right: 0;
    top: 0;
  }

  .header__logo {
    height: 45px;
    transition: all .2s ease;
    width: 125px;
  }
}

.scroll-header {
 box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
 height: 30px;

  .header__button {
    margin: 0;
  }

  .header__logo {
    height: 30px;
    width: 105px
  }

  .header__group .header__block {
    height: calc(100vh - 29px);
  }
}