.home__pack {
  height: 120px;

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    height: 180px;
  }

  @media screen and (min-width: $screen-sm-min) {
    height: 275px;
  }
}

@mixin border-home {
  border-bottom: 5px solid $brand-darker;
  border-top: 5px solid $brand-darker;
}

@mixin border-home-top {
  border-bottom: 5px solid $brand-darker;
  border-top: 10px solid $brand-darker;
}

.home__pack--borderline,
.home__pack--borderline-top,
.home__pack--borderline-bottom-mobile {
  @include border-home;
}

.home__pack--borderline-top-mobile {
  @include border-home-top;
}

.home__pack--borderline-bottom {
  border-bottom: 10px solid $brand-darker;
  border-top: 5px solid $brand-darker;
}

.home__pack--borderline-left,
.home__pack--borderline-right,
.home__pack--borderline-center {
  border-left: 10px solid $brand-darker;
  border-right: 10px solid $brand-darker;
}

.brick-responsive {
  display: flex;
  flex-flow: wrap;
}

@media screen and (min-width: $screen-sm-min) {
  .home__pack--borderline-top-mobile,
  .home__pack--borderline-bottom-mobile {
    @include border-home;
  }

  .home__pack--borderline-top {
    @include border-home-top;
  }

  .home__pack--borderline-left {
    border-left: 5px solid $brand-darker;
    border-right: none;
  }

  .home__pack--borderline-right {
    border-left: none;
    border-right: 5px solid $brand-darker;
  }

  .home__pack--borderline-center {
    border-left: 5px solid $brand-darker;
    border-right: 5px solid $brand-darker;
  }

  .brick-responsive__reorder--a {
    order: 1;
  }

  .brick-responsive__reorder--b {
    order: 2;
  }
}

.home-brick {
  color: $color-text;
  justify-content: center;
  padding: 3%;

  * {
    text-shadow: 1px 1px 1px $brand-darker;
  }

  @media screen and (min-width: $screen-sm-min) {
    justify-content: flex-end;
  }

  @media screen and (min-width: $screen-mg-min) {
    padding: 20px 25px;
  }

  .home-brick__title {
    font-size: 8vw;
    line-height: 90%;

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-mg-max) {
      font-size: 6vw;
    }

    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      font-size: 65px;
    }

    @media screen and (min-width: $screen-gd-min) {
      font-size: 75px;
    }
  }

  .home-brick__info {
    font-size: $font-size-h-6;
    height: auto;
    margin: 5px 0 0;
    min-height: 45px;

    @media screen and (min-width: $screen-lg-min) {
      width: 75%;
    }
  }
}

#home-top, #home-middle, #home-footer {

  img {
    width: 100%;

    @media screen and (max-width: $screen-xs-max) {
      height: auto;
    }
  }
}

#home-top, #home-middle {

  img {
    @media screen and (min-width: $screen-sm-min) {
      object-fit: cover;
    }
  }
}
