.carousel-inner {
  background: $darker-strong;
  width: auto;
}

.carousel-control {
  align-items: center;
  background-color: rgba($brand-primary, .4);
  background-image: none;
  border-radius: 100% / 50%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  height: 100px;
  margin-top: 130px;
  opacity: 0.5;
  width: 30px;

  @media screen and (min-width: $screen-xs-min) {
    height: 160px;
    width: 45px;
  }

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and  (min-width: $screen-md-min) {

    &:hover {
      background: rgba($brand-primary, .6);
      opacity: 0.6;
      box-shadow: 0 0 15px $dark;

      span {
        opacity: 1;
        text-shadow: 0 0 5px $dark;
      }
    }
  }

  span {
    font-size: $font-size-llarge;
    opacity: 0.8;
    transform: rotate(180deg);

    @media screen and (min-width: $screen-xs-min) {
      font-size: $font-size-xlarge;
    }
  }
}

.carousel-control--inactive {
  display: none;
}

.right {
  transform:  rotate(180deg);
}

.carousel-item__info {
  padding: 5px 25px 20px;
  font-family: $font-family-bold;

  @media screen and (max-width: $screen-ss-max) {
    right: 0;
    width: 100%;
  }

  @media screen and (max-width: $screen-xs-max) {
    height: 165px;
    padding: 0 15px;
  }

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
    height: 320px;
  }

  @media screen and (min-width: $screen-md-min) {
    &:before {
      background: linear-gradient(
        to top,
        rgba(26,26,26,0.9) 0%,
        rgba(77,77,77,0.8) 50%,
        rgba(26,26,26,0.9) 100%);
      content: "";
      float: left;
      height: 170px;
      margin-left: -25px;
      width: 1px;
    }
  }
}

.carousel-item__position {
  align-items: center;
  color: $brand-primary;
  display: flex;
  font-size: 15vw;
  margin: 0;

  @media screen and (min-width: $screen-md-min) {
    font-size: 55px;
  }
}

.carousel-item--size {

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
    height: 500px;
  }
}

.carousel-item__position--short {
  color: inherit;
  font-size: 5vw;

  @media screen and (min-width: $screen-md-min) {
    font-size: $font-size-xsmall;
  }
}

.carousel-item__name {
  font-size: $font-size-llarge;
  margin: 0;

  @media screen and (min-width: $screen-xs-min) {
    font-size: $font-size-mlarge;
    margin: 10px 0 0;
  }
}

.carousel-item__data {
  margin: 3px 0;
  font-size: $font-size;
}

.carousel-2__info {
  display: inline-block;
  font-size: $font-size-msmall;
  list-style: none;
  margin-right: 10px;
}

.carousel-2__title {
  color: $brand-select;

  @media screen and (max-width: $screen-ss-max) {
    font-size: $font-size-xsmall;
    margin: 0;
  }
}

.carousel-2__subtitle {
  color: $brand-primary;
  font-size: $font-size;

  @media screen and (max-width: $screen-ss-max) {
    font-size: $font-size-msmall;
  }
}

.carousel-2__icon {
  display: block;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 75px;

  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    margin: 0 0 20px -15px;
    position: initial;
  }

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    margin: -5px 0 20px -25px;
    position: initial;
  }
}

.footer__carousel-poster {

  @media screen and (min-width: $screen-lg-min) {
    width: 100%;
  }
}
