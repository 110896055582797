iframe {
  border: 0;
}

.close {
  height: 100%;
  opacity: 1;
  width: 100%;

  &:hover {
    opacity: 1;
  }
}

.modal-body {
  padding: 0;
}

.modal-content {
  background: #000;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}